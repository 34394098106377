import React from "react"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { SectionHeader } from "./sectionHeader"
import styled from "styled-components"
import { Separator } from "./separators/separator"
import { useSectionData } from "../utilities/firebase"
import { SectionContainer } from "./sectionContainer"

const HistoryHeader = styled(SectionHeader)`
    text-align: center;
`

const Image = styled.img`
    width: 100%;
    height: 50vh;
    object-fit: cover;
    @media (min-width: 768px) {
        height: 50vh;
    }
`

type SectionData = {
    title: string
    description: string
    imageURL: string
}

export const HistorySection = () => {
    const sectionData = useSectionData<SectionData>("l2kGPjBvTN9x47W8383v");
    return <SectionContainer sectionId="historia">
        <div id="historia" style={{ position: "absolute", top: -48 }} />
        <Separator variant="A" />
        <Container>
            <Row>
                <Col>
                    <HistoryHeader>{sectionData.title}</HistoryHeader>
                    <p style={{ maxWidth: 800, margin: "auto" }}>
                        {sectionData.description}
                    </p>
                    <p style={{ textAlign: "center" }}>¡Salud!</p>
                </Col>
            </Row>
        </Container>
        <Image src={sectionData.imageURL} />
    </SectionContainer>
}