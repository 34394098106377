import React from "react";

const SeparatorA = () => <svg style={{ maxWidth: 350, }} fill="inherit" viewBox="0 0 492 41" >
    <g>
        <path d="M184.1,19.2c2.1,0.9,4.3,1.1,6.3,1.2c2.1,0.1,4.1,0.1,6.2,0.1c8.3,0,16.1-0.1,23.1,0.9
c7,0.9,13.1,2.9,17.7,5.5c3.9,2.2,6.8,4.8,8.7,7c1.9-2.2,4.7-4.8,8.7-7c4.6-2.6,10.7-4.6,17.7-5.5c7-1,14.8-0.8,23.1-0.9
c2.1,0,4.1,0,6.2-0.1c2.1-0.1,4.3-0.4,6.3-1.2c4.2-1.9,6.7-6.2,6.5-10.4c-0.1-2.1-1-4-2.3-5.5c-1.2-1.5-2.9-2.5-4.6-3
c-1.8-0.5-3.6-0.3-5.2,0.3c-1.5,0.6-2.8,1.7-3.7,2.8c-0.9,1.2-1.4,2.6-1.6,3.9c-0.1,1.3,0.2,2.6,0.7,3.7c0.5,1,1.3,1.9,2.2,2.4
c0.9,0.5,1.8,0.7,2.6,0.6c1.6-0.2,2.6-1.1,3.1-1.7c0.2-0.3,0.3-0.6,0.4-0.8c0.1-0.2,0.1-0.3,0.1-0.3s0,0.1-0.1,0.3
c-0.1,0.2-0.2,0.5-0.5,0.8c-0.5,0.6-1.5,1.3-3,1.3c-0.7,0-1.5-0.2-2.2-0.7c-0.7-0.5-1.3-1.2-1.7-2.2c-0.4-0.9-0.5-2-0.3-3.1
c0.2-1.1,0.7-2.2,1.5-3.1c1.6-1.8,4.4-3,7-2.1c2.6,0.8,4.8,3.6,4.9,6.6c0.1,3-2,6.2-5,7.4c-1.5,0.6-3.4,0.8-5.3,0.9
c-2,0.1-4,0.1-6.1,0.1c-8.2,0-16.3,0.1-23.5,1.4c-7.2,1.2-13.5,3.7-18.1,6.8c-3.6,2.4-6.1,5-7.8,7.3c-1.7-2.3-4.3-4.9-7.8-7.3
c-4.6-3.1-10.9-5.6-18.1-6.8c-7.2-1.3-15.2-1.4-23.5-1.4c-2.1,0-4.1,0-6.1-0.1c-1.9-0.1-3.8-0.3-5.3-0.9c-3-1.2-5.1-4.5-5-7.4
c0-3,2.3-5.7,4.9-6.6c2.6-0.9,5.4,0.2,7,2.1c0.8,0.9,1.3,2,1.5,3.1c0.2,1.1,0,2.2-0.3,3.1c-0.4,0.9-1,1.7-1.7,2.2
c-0.7,0.5-1.5,0.7-2.2,0.7c-1.5,0-2.5-0.7-3-1.3c-0.2-0.3-0.4-0.6-0.5-0.8C186,11.1,186,11,186,11s0,0.1,0.1,0.3
c0.1,0.2,0.2,0.5,0.4,0.8c0.4,0.7,1.4,1.6,3.1,1.7c0.8,0.1,1.8,0,2.6-0.6c0.9-0.5,1.6-1.3,2.2-2.4c0.5-1,0.8-2.3,0.7-3.7
c-0.1-1.3-0.7-2.7-1.6-3.9c-0.9-1.2-2.2-2.2-3.7-2.8c-1.5-0.6-3.4-0.8-5.2-0.3c-1.8,0.5-3.4,1.5-4.6,3c-1.2,1.5-2.2,3.4-2.3,5.5
C177.4,13,179.9,17.3,184.1,19.2z"/>
        <path d="M203.9,12.4c1.3,0.8,2.6,1.1,4,1.4c1.3,0.2,2.7,0.3,4,0.4c2.5,0.2,5.2,0.5,7.8,1.1c5.3,1.1,10.2,2.8,14.4,4.9
c4.2,2,7.7,4.4,10.2,7c0.7,0.7,1.3,1.5,1.9,2.2c0.5-0.7,1.2-1.5,1.9-2.2c2.4-2.6,5.9-4.9,10.2-7c4.2-2,9.1-3.8,14.4-4.9
c2.6-0.6,5.3-0.9,7.8-1.1c1.3-0.1,2.6-0.2,4-0.4c1.3-0.2,2.7-0.6,4-1.4c1.2-0.7,2.3-2,2.7-3.3c0.5-1.3,0.4-2.7,0-4
c-0.4-1.3-1.2-2.3-2.2-2.9c-1-0.7-2.1-1-3.1-1.1c-2.1-0.1-4,1.1-4.6,2.7c-0.6,1.6,0.1,3.2,1.1,3.7c1,0.5,2,0.2,2.4-0.1
c0.5-0.3,0.6-0.6,0.6-0.6c0,0-0.2,0.3-0.6,0.5c-0.4,0.2-1.3,0.3-2.1-0.2c-0.7-0.5-1.1-1.8-0.5-2.9c0.6-1.1,2.1-1.8,3.6-1.5
c1.5,0.2,3.1,1.4,3.4,3c0.2,0.8,0.1,1.7-0.2,2.5c-0.3,0.8-1,1.4-1.8,1.9c-0.8,0.4-1.9,0.7-3,0.8c-1.2,0.1-2.4,0.2-3.7,0.2
c-2.7,0.1-5.5,0.4-8.3,1c-5.6,1.1-10.7,3.2-15,5.6c-4.3,2.4-7.9,5.3-10.1,8.3c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2-0.3-0.4-0.6-0.7-0.9
c-2.3-3-5.8-5.9-10.1-8.3c-4.3-2.4-9.4-4.5-15-5.6c-2.8-0.6-5.6-0.9-8.3-1c-1.3-0.1-2.6-0.1-3.7-0.2c-1.1-0.1-2.2-0.4-3-0.8
c-0.8-0.4-1.5-1.1-1.8-1.9c-0.3-0.8-0.4-1.7-0.2-2.5c0.3-1.6,1.9-2.8,3.4-3c1.5-0.3,3,0.5,3.6,1.5c0.6,1.1,0.3,2.4-0.5,2.9
c-0.8,0.5-1.6,0.4-2.1,0.2c-0.5-0.2-0.6-0.5-0.6-0.5c0,0,0.1,0.3,0.6,0.6C208,7.7,209,8,210,7.5c1-0.5,1.7-2.1,1.1-3.7
c-0.6-1.6-2.5-2.8-4.6-2.7c-1.1,0.1-2.2,0.4-3.1,1.1c-1,0.7-1.8,1.7-2.2,2.9c-0.4,1.2-0.4,2.6,0,4
C201.7,10.4,202.7,11.6,203.9,12.4z"/>
        <path d="M486.7,22.3l-9.9,3.1H332c-0.3-0.9-0.7-1.7-1.2-2.5c-0.5-0.7-1.2-1.3-1.9-1.7c-0.7-0.4-1.5-0.7-2.3-0.8
c-1.6-0.2-3.2,0.1-4.4,1c-1.2,0.9-1.8,2.2-2,3.4c0,0.2,0,0.4,0,0.6h-25.9c-4.2-1.2-8.9-2-13.8-2.2c-5.8-0.2-11.3,0.3-16.2,1.6
c-4.9,1.3-9.1,3.3-12.1,5.5c-2.9,2.1-4.8,4.2-6,5.9c-1.2-1.7-3.2-3.8-6-5.9c-3.1-2.2-7.2-4.3-12.1-5.5c-4.9-1.3-10.4-1.8-16.2-1.6
c-4.9,0.2-9.6,1-13.8,2.2h-25.9c0-0.2,0-0.4,0-0.6c-0.1-1.2-0.8-2.6-2-3.4c-1.2-0.9-2.8-1.2-4.4-1c-0.8,0.1-1.6,0.4-2.3,0.8
c-0.7,0.4-1.4,1-1.9,1.7c-0.6,0.7-1,1.6-1.2,2.5H15.5l-9.9-3.1L0,24.4v6.2l5.5,2.1l9.9-3.1H160c0.3,1.4,0.9,2.7,1.7,3.9
c1.1,1.6,2.7,3,4.4,4c3.6,2.1,8,2.6,12.2,2.1c4.3-0.5,8.4-2,12.5-3.5c4-1.5,7.9-2.8,11.8-3.6c3.9-0.8,7.7-1.3,11.4-1.4
c7.3-0.3,14,0.8,19.3,2.3c2.7,0.8,5,1.7,6.9,2.9c1.9,1.1,3.3,2.4,4.4,3.5c0.6,0.6,1,1.3,1.4,1.8c0.4-0.5,0.8-1.1,1.4-1.8
c1.1-1.2,2.5-2.4,4.4-3.5c1.9-1.1,4.2-2.1,6.9-2.9c5.4-1.5,12-2.5,19.3-2.3c3.7,0.1,7.5,0.5,11.4,1.4c3.9,0.8,7.8,2.1,11.8,3.6
c4,1.5,8.2,3,12.5,3.5c4.3,0.5,8.7-0.1,12.2-2.1c1.8-1,3.3-2.4,4.4-4c0.8-1.2,1.4-2.5,1.7-3.9h144.6l9.9,3.1l5.5-2.1v-6.2
L486.7,22.3z M321.1,25c0.2-1,0.8-2,1.7-2.5c0.9-0.6,2.2-0.8,3.4-0.5c1.2,0.3,2.3,1,3,2.1c0.3,0.4,0.5,0.9,0.6,1.4h-8.8
C321.1,25.2,321.1,25.1,321.1,25z M317.7,29.6c-0.3,0.3-0.6,0.5-1,0.8c-0.8,0.5-2,1-3.4,1.1c-1.4,0.2-3-0.1-4.7-0.7
c-1-0.3-2-0.8-3-1.2H317.7z M186.6,29.6c-1.1,0.5-2.1,0.9-3,1.2c-1.7,0.6-3.3,0.8-4.7,0.7c-1.4-0.1-2.6-0.6-3.4-1.1
c-0.4-0.3-0.7-0.5-1-0.8H186.6z M163,24c0.7-1.1,1.8-1.8,3-2.1c1.2-0.3,2.4-0.1,3.4,0.5c0.9,0.6,1.5,1.6,1.7,2.5
c0,0.1,0,0.3,0.1,0.4h-8.8C162.5,24.9,162.7,24.4,163,24z M328.5,32.1c-0.9,1.3-2.3,2.3-3.7,3.1c-3,1.6-6.8,2-10.6,1.5
c-3.8-0.5-7.7-1.9-11.7-3.5c-4-1.5-8.2-2.9-12.3-3.6c-4.1-0.8-8.1-1.1-12-1.1c-7.7,0-14.5,1.5-19.9,3.4c-2.7,1-5.1,2.2-7,3.5
c-1.9,1.3-3.3,2.8-4.2,4.1c-0.4,0.6-0.8,1.1-1,1.6c-0.3-0.5-0.6-1-1-1.6c-1-1.3-2.4-2.7-4.2-4.1c-1.9-1.3-4.2-2.5-7-3.5
c-5.4-1.9-12.2-3.4-19.9-3.4c-3.8,0-7.8,0.3-12,1.1c-4.1,0.7-8.3,2.1-12.3,3.6c-4,1.5-7.9,2.9-11.7,3.5c-3.8,0.5-7.6,0.1-10.6-1.5
c-1.5-0.8-2.8-1.8-3.7-3.1c-0.6-0.8-1-1.6-1.3-2.5h5.3c0.2,0,0.3,0,0.5,0h6c0.3,0.4,0.6,0.8,1.1,1.2c0.8,0.7,2,1.4,3.5,1.7
c1.5,0.4,3.4,0.3,5.2-0.1c1.9-0.4,3.8-1.2,5.9-1.9c2-0.7,4.2-1.4,6.6-1.9c4.7-1.2,9.9-2,15.4-2.2c5.5-0.2,10.8,0.1,15.5,1
c4.7,0.9,8.7,2.4,11.9,4.3c3.2,1.8,5.4,3.8,6.8,5.3c0,0,0.1,0.1,0.1,0.1c0,0,0.1-0.1,0.1-0.1c1.4-1.5,3.6-3.5,6.8-5.3
c3.1-1.8,7.2-3.4,11.9-4.3c4.7-0.9,10-1.2,15.5-1c5.5,0.2,10.8,1,15.4,2.2c2.3,0.6,4.5,1.2,6.6,1.9c2,0.7,4,1.5,5.9,1.9
c1.9,0.4,3.7,0.5,5.2,0.1c1.5-0.3,2.7-1,3.5-1.7c0.5-0.4,0.8-0.8,1.1-1.2h6c0.2,0,0.4,0,0.5,0h5.3
C329.5,30.5,329.1,31.3,328.5,32.1z"/>
    </g>
</svg >

const SeparatorB = () => <svg style={{ maxWidth: 350, }} fill="inherit" viewBox="0 0 492 57">
    <g>
        <path d="M287.3,26.4C287.3,26.3,287.4,26.3,287.3,26.4c0.9-1.1,1.5-2.4,1.8-3.7c0.3-1.3,0.2-2.6-0.1-3.8
   c-0.6-2.4-2-4.4-3.9-5.7c-1.9-1.3-4.1-1.7-6.1-1.4c-1.9,0.3-3.7,1.5-4.5,3c-0.8,1.5-0.8,3.1-0.3,4.3c0.6,1.2,1.5,1.8,2.3,2.1
   c0.8,0.3,1.5,0.2,1.9,0.1c0.2-0.1,0.4-0.1,0.5-0.2c0.1,0,0.1-0.1,0.1-0.1c0,0-1,0.4-2.2-0.4c-0.6-0.4-1.2-1.1-1.4-1.9
   c-0.2-0.9,0.1-2,0.8-2.9c0.7-0.9,1.8-1.4,3.1-1.4c1.3,0,2.7,0.4,3.8,1.4c1.1,0.9,1.9,2.3,2.2,3.8c0.3,1.5,0,3-1,4.2
   c-1,1.2-2.7,3.1-7.2,2.2c-4.6-0.9-21.3-9.5-23.3-10.3c-1.5-0.6-3.5-1.9-4.3-3.1c-0.8-1.3-1.3-3-1-4.5c0.2-1.6,1.1-2.8,2.4-3.6
   c1.3-0.8,2.9-1.2,4.3-1c1.4,0.2,2.7,0.9,3.6,1.8c0.9,1,1.3,2.2,1.1,3.3c-0.2,1.1-0.8,2-1.6,2.5c-0.8,0.5-1.7,0.5-2.4,0.3
   c-1.4-0.4-1.8-1.4-1.8-1.3c0,0,0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.2,0.4c0.2,0.4,0.6,0.9,1.4,1.3c0.7,0.4,1.8,0.7,3.1,0.3
   c1.2-0.4,2.4-1.6,3-3.2c0.6-1.6,0.3-3.7-0.8-5.3c-1-1.7-2.9-3-5.1-3.5c-2.2-0.5-4.6-0.2-6.8,1c-1.1,0.6-2.1,1.5-2.9,2.6
   C246.1,3.9,246,4,246,4.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.8-1.1-1.8-2-2.9-2.6c-2.2-1.2-4.6-1.5-6.8-1c-2.2,0.5-4.1,1.9-5.1,3.5
   c-1.1,1.6-1.4,3.7-0.8,5.3c0.5,1.6,1.7,2.7,3,3.2c1.3,0.4,2.4,0.1,3.1-0.3c0.7-0.4,1.2-0.9,1.4-1.3c0.1-0.2,0.2-0.3,0.2-0.4
   c0-0.1,0-0.2,0-0.2c0-0.1-0.4,0.9-1.8,1.3c-0.7,0.2-1.6,0.2-2.4-0.3c-0.8-0.5-1.4-1.4-1.6-2.5c-0.2-1.1,0.2-2.3,1.1-3.3
   c0.9-1,2.1-1.7,3.6-1.8c1.4-0.2,3,0.2,4.3,1c1.3,0.8,2.2,2.1,2.4,3.6c0.2,1.6-0.2,3.2-1,4.5c-0.8,1.3-2.8,2.6-4.3,3.1
   c-2,0.8-18.7,9.4-23.3,10.3c-4.6,0.9-6.2-1-7.2-2.2c-1-1.2-1.3-2.7-1-4.2c0.3-1.5,1.1-2.9,2.2-3.8c1.1-0.9,2.4-1.4,3.8-1.4
   c1.3,0,2.5,0.6,3.1,1.4c0.7,0.9,1,2,0.8,2.9c-0.2,0.9-0.8,1.6-1.4,1.9c-1.3,0.8-2.3,0.4-2.2,0.4c0,0,0,0,0.1,0.1
   c0.1,0.1,0.2,0.1,0.5,0.2c0.4,0.1,1.1,0.2,1.9-0.1c0.8-0.3,1.8-0.9,2.3-2.1c0.5-1.2,0.5-2.9-0.3-4.3c-0.8-1.5-2.5-2.7-4.5-3
   c-1.9-0.4-4.2,0.1-6.1,1.4c-1.8,1.3-3.3,3.3-3.9,5.7c-0.3,1.2-0.4,2.5-0.1,3.8c0.3,1.3,0.9,2.6,1.7,3.6c0,0,0.1,0.1,0.1,0.1H18
   l-4.8-1.9v8.1l4.8-1.9h186.7c0,0-0.1,0.1-0.1,0.1c-0.8,1-1.5,2.3-1.7,3.6c-0.3,1.3-0.2,2.6,0.1,3.8c0.6,2.4,2,4.4,3.9,5.7
   c1.9,1.3,4.1,1.7,6.1,1.4c1.9-0.3,3.7-1.5,4.5-3c0.8-1.5,0.8-3.1,0.3-4.3c-0.6-1.2-1.5-1.8-2.3-2.1c-0.8-0.3-1.5-0.2-1.9-0.1
   c-0.2,0.1-0.4,0.1-0.5,0.2c-0.1,0-0.1,0.1-0.1,0.1c0,0,1-0.3,2.2,0.4c0.6,0.4,1.2,1.1,1.4,1.9c0.2,0.9-0.1,2-0.8,2.9
   c-0.7,0.9-1.8,1.4-3.1,1.4c-1.3,0-2.7-0.4-3.8-1.4c-1.1-0.9-1.9-2.3-2.2-3.8c-0.3-1.5,0-3,1-4.2c1-1.2,2.7-3.1,7.2-2.2
   c4.6,0.9,21.3,9.5,23.3,10.3c1.5,0.6,3.5,1.9,4.3,3.1c0.8,1.3,1.3,3,1,4.5c-0.2,1.6-1.1,2.8-2.4,3.6c-1.3,0.8-2.9,1.2-4.3,1
   c-1.4-0.2-2.7-0.9-3.6-1.8c-0.9-1-1.3-2.2-1.1-3.3c0.2-1.1,0.8-2,1.6-2.5c0.8-0.5,1.7-0.5,2.4-0.3c1.4,0.4,1.8,1.4,1.8,1.3
   c0,0,0-0.1,0-0.2c0-0.1-0.1-0.2-0.2-0.4c-0.2-0.4-0.6-0.9-1.4-1.3c-0.7-0.4-1.8-0.7-3.1-0.3c-1.2,0.4-2.4,1.6-3,3.2
   c-0.6,1.6-0.3,3.7,0.8,5.3c1,1.7,2.9,3,5.1,3.5c2.2,0.5,4.6,0.2,6.8-1c1.1-0.6,2.1-1.5,2.9-2.6c0.1-0.1,0.1-0.2,0.2-0.3
   c0.1,0.1,0.1,0.2,0.2,0.3c0.8,1.1,1.8,2,2.9,2.6c2.2,1.2,4.6,1.5,6.8,1c2.2-0.5,4.1-1.9,5.1-3.5c1.1-1.6,1.4-3.7,0.8-5.3
   c-0.5-1.6-1.7-2.7-3-3.2c-1.3-0.4-2.4-0.1-3.1,0.3c-0.7,0.4-1.2,0.9-1.4,1.3c-0.1,0.2-0.2,0.3-0.2,0.4c0,0.1-0.1,0.2-0.1,0.2
   c0,0.1,0.4-0.9,1.8-1.3c0.7-0.2,1.6-0.2,2.4,0.3c0.8,0.5,1.4,1.4,1.6,2.5c0.2,1.1-0.2,2.3-1.1,3.3c-0.9,1-2.1,1.7-3.6,1.8
   c-1.4,0.2-3-0.2-4.3-1c-1.3-0.8-2.2-2.1-2.4-3.6c-0.2-1.6,0.2-3.2,1-4.5c0.8-1.3,2.8-2.6,4.3-3.1c2-0.8,18.7-9.4,23.3-10.3
   c4.6-0.9,6.2,1,7.2,2.2c1,1.2,1.3,2.7,1,4.2c-0.3,1.5-1.1,2.9-2.2,3.8c-1.1,0.9-2.4,1.4-3.8,1.4c-1.3,0-2.4-0.6-3.1-1.4
   c-0.7-0.9-1-2-0.8-2.9c0.2-0.9,0.8-1.6,1.4-1.9c1.3-0.8,2.3-0.4,2.2-0.4c0,0,0,0-0.1-0.1c-0.1-0.1-0.2-0.1-0.5-0.2
   c-0.4-0.1-1.1-0.2-1.9,0.1c-0.8,0.3-1.8,0.9-2.3,2.1c-0.5,1.2-0.5,2.9,0.3,4.3c0.8,1.5,2.5,2.7,4.5,3c1.9,0.4,4.2-0.1,6.1-1.4
   c1.8-1.3,3.3-3.3,3.9-5.7c0.3-1.2,0.4-2.5,0.1-3.8c-0.3-1.3-0.9-2.6-1.7-3.6c0,0-0.1-0.1-0.1-0.1H474l4.8,1.9v-8.1l-4.8,1.9H287.3z
    M249.3,39.7c-1.1,0.6-2,1.4-2.8,2.4c-0.2,0.3-0.4,0.6-0.5,0.9c-0.2-0.3-0.3-0.6-0.5-0.9c-0.7-1-1.7-1.8-2.8-2.4
   c-1-0.5-17.5-8.7-23.6-11.3c6.1-2.6,22.6-10.7,23.6-11.3c1.1-0.6,2-1.4,2.8-2.4c0.2-0.3,0.4-0.6,0.5-0.9c0.2,0.3,0.3,0.6,0.5,0.9
   c0.7,1,1.7,1.8,2.8,2.4c1,0.5,17.5,8.7,23.6,11.3C266.8,31.1,250.2,39.2,249.3,39.7z"/>
        <polygon points="480.8,32.9 485.5,34.2 485.5,22.8 480.8,24 	" />
        <path d="M487.5,22.8h-0.3v11.4h0.3c2.4,0,4.4-2,4.4-4.4v-2.5C491.9,24.8,490,22.8,487.5,22.8z" />
        <polygon points="6.4,34.2 11.2,32.9 11.2,24 6.4,22.8 	" />
        <path d="M0,27.2v2.5c0,2.4,2,4.4,4.4,4.4h0.3V22.8H4.4C2,22.8,0,24.8,0,27.2z" />
    </g>
</svg>

export const Separator = ({variant}: {variant: "A" | "B"}) => {
    return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 16 }}>
        {
            variant === "A"
                ? <SeparatorA />
                : <SeparatorB />
        }
    </div>
}