import * as React from "react"

export const Icon = () => {
     return <svg fill="inherit" preserveAspectRatio="none" viewBox="0 0 256 256">

          <g>
               <path  d="M119.53,70.71c0,0-5.83,5.5-5.92,21.6h0v16.81H92.49v23.79h21.12v61.47h24.36v-61.47h20.95l2.92-23.79h-23.87
		V92.31h-0.01c0.06-1.88,0.89-10.16,10.68-9.99h14.26V59.92h-21.36C141.53,59.92,128.1,59.58,119.53,70.71z"/>
               <g>
                    <path d="M216.07,206.02c-3.49,1.04-5.43,4.93-7.09,7.93c-1.51,2.74-4.4,5.94-4.4,9.14c0,1.04,1.05,1.85,2.08,1.56
			c3.09-0.88,5.41-4.48,7.64-6.65c2.47-2.4,5.77-5.33,5.82-8.94C220.15,207.05,218.04,205.43,216.07,206.02z"/>
                    <path d="M253.42,136.04c15.13-61.1-48-122.87-105.43-133.75c-31.56-5.98-64.4-0.29-91.33,17.24
			C33.53,34.59,9.88,61.35,6.97,89.64c-0.06,0.62,0.12,1.13,0.4,1.57C-10.84,137.95,9,196.72,48.95,226.36
			c39.61,29.4,104.72,39.72,146.84,9c3.55-2.59,0.58-7.76-3.48-5.88c-1.53,0.71-3.05,1.41-4.58,2.1c-0.77-1.33-2.36-2.19-4.35-1.24
			c-28.04,13.3-66.98,6.58-94.7-3.3c-3.13-1.11-5.28,3.88-2.27,5.33c14.76,7.15,30.81,11.36,47.25,12.24
			c6.87,0.36,13.76,0.12,20.6-0.61c-10.45,2.62-21.26,3.84-32.88,2.79c-23.6-2.14-44.48-10.93-63.99-23.97
			c-22.87-15.28-38.49-33.91-45.9-60.56c-6.42-23.09-6.76-47.4,0.34-70.25c0.31-0.22,0.59-0.49,0.78-0.86
			c1.24-2.33,2.45-4.63,3.65-6.91c-7.01,18.08-9.08,38.13-5.93,57.27c5.97,36.27,26.4,60.24,56.5,79.92
			c3.61,2.36,7.23-3.71,3.39-5.74c-26.72-14.15-47.9-46.37-52.38-75.8c-4.95-32.52,6.87-63.02,26.21-88.91
			c1.65-2.21-1.85-4.62-3.74-2.85c-3.18,2.98-6.11,6.17-8.81,9.52c4.44-6.65,9.39-12.93,15.34-18.75c-1.36,2.28,0.82,5.75,3.57,3.98
			c7.03-4.51,13.81-9.3,21.11-13.41c5.19-2.93,10.73-5.27,16.07-7.94c0.86,0.28,1.86,0.33,3.02,0.03
			C129.31,11.75,167.84,16,198,44.28c1.17,1.1,2.61,0.6,3.32-0.39c-0.28,1.41,0.49,3.05,2.37,3.05c3.27,0,3.28-5.03,0-5.03
			c-0.92,0-1.55,0.41-1.96,0.98c0.07-0.57-0.08-1.17-0.6-1.72c-10.07-10.61-22.75-19.47-36.7-25.69
			c40.78,14.93,74.68,51.44,79.57,93.58c-3.05-13.47-8.65-26.47-16.89-38.24c-2.08-2.97-6.74-0.39-4.96,2.86
			c10.96,20.05,16.14,41.97,16.86,64.68c0.76,23.73-8.46,43.95-21.07,63.53c-1.28,1.99,1.68,4.42,3.32,2.78
			c0.75,0.39,1.71,0.4,2.51-0.35c18.21-16.97,31.54-42.45,29.74-67.91C253.5,136.28,253.44,136.17,253.42,136.04z"/>
                    <path d="M76.43,224.46c-0.8,1.87,0.27,3.73,2.13,4.38c1.36,0.47,2.61,0.94,4.04,1.12c2.47,0.31,3.38-3.05,1.91-4.57
			c-0.97-1-2.27-1.71-3.43-2.48C79.44,221.82,77.15,222.79,76.43,224.46z"/>
               </g>
          </g>
     </svg>
}