import React, { useEffect, useState } from "react"
import styled from "styled-components"

import Container from "react-bootstrap/Container"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"

import iconURI from "../images/icon-white.svg"
import { debounce } from "../utilities/helper"

import { scroller } from "react-scroll"

const StyledNavbar = styled(Navbar)`
    color: white;
    transition: .25s background-color;
`

const Shadow = styled.div`
    position: fixed;
    width: 100%;
    top: 0;
    height: 80px;
    z-index: 1029;
    background-image: linear-gradient(rgba(0,0,0,0.75), transparent);
    transition: .25s opacity;
`

export const Navigation = () => {
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [onTop, setOnTop] = useState(true);
    const [expanded, setExpanded] = useState(false);

    const handleScroll = debounce(() => {
        const currentScrollPos = window.pageYOffset;

        setOnTop(currentScrollPos <= 0);

        setPrevScrollPos(currentScrollPos);
    }, 100, true);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);

    }, [prevScrollPos, onTop, handleScroll]);

    const scrollToSection = (sectionID: string) => {
        scroller.scrollTo(sectionID, { });
    }

    const onLinkClicked = () => {
        setExpanded(false);
    }

    return <React.Fragment>
        <Shadow style={{ opacity: onTop ? 1 : 0 }} />
        <StyledNavbar expanded={expanded} onToggle={setExpanded} variant="dark" fixed="top" expand="lg" style={{ backgroundColor: (onTop && !expanded) ? "transparent" : "#8d1618", }}>
            <Container>
                <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src={iconURI}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    Freunde Von Melo
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link onClick={onLinkClicked} href="#historia">Historia</Nav.Link>
                        <Nav.Link onClick={onLinkClicked} href="#variedades">Variedades</Nav.Link>
                        <Nav.Link onClick={onLinkClicked} href="#atardeceres">Atardeceres</Nav.Link>
                        <Nav.Link onClick={onLinkClicked} href="#regalos">Regalos</Nav.Link>
                        <Nav.Link onClick={onLinkClicked} href="#contacto">Contacto</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </StyledNavbar>
    </React.Fragment>
}