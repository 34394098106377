import React from "react"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import styled from "styled-components"
import { FacebookIcon, InstagramIcon, LocationIcon, TikTokIcon, WhatsAppIcon } from "./icons"
import { SectionContainer } from "./sectionContainer"

const SocialIcon = styled.a`
  svg, img {
    transition: background-color 0.5s, fill 0.5s;
    border-radius: 100%;

    width: 56px;
    height: 56px;
    
    padding: 4px;
    background-color: transparent;
    fill: white;

    :hover {
      background-color: white;
      fill: #8d1b1b;
    }
  }
`

const DisclaimerCol = styled.div`
  font-size: .7em;
  border-top: 2px white solid;
  margin-top: 32px;
  padding-top: 16px;
  
  @media (min-width: 768px) {
    font-size: .75em;
  }
`

export const FooterSection = () => {
  return <SectionContainer offset={0} style={{ backgroundColor: "#8D1618", color: "white", padding: "32px 0px", textAlign: "center" }} sectionId="contacto">
    <Container>
      <Row>
        <Col xs={12}>
          <div>Seguinos</div>
        </Col>
        <Col style={{ marginTop: 8 }}>
          <SocialIcon target="_blank" href="https://www.instagram.com/freundevonmelo_cerveza/">
            <InstagramIcon />
          </SocialIcon>
          <SocialIcon target="_blank" href="https://vm.tiktok.com/ZMLRD9hsF/">
            <TikTokIcon />
          </SocialIcon>
          <SocialIcon target="_blank" href="https://www.facebook.com/profile.php?id=100063638917713">
            <FacebookIcon />
          </SocialIcon>
        </Col>
      </Row>
      <Row style={{ marginTop: 16 }}>
        <Col xs={12}>
          <div>Escribinos</div>
        </Col>
        <Col style={{ marginTop: 8 }}>
          <SocialIcon target="_blank" href='https://wa.me/5493385596314'>
            <WhatsAppIcon />
          </SocialIcon>
        </Col>
      </Row>
      <Row style={{ marginTop: 16 }}>
        <Col xs={12}>
          <div>Visitanos</div>
        </Col>
        <Col style={{ marginTop: 8 }}>
          <SocialIcon target="_blank" href="https://maps.app.goo.gl/DAmzrPg7tarUCukVA">
            <LocationIcon />
          </SocialIcon>
        </Col>
      </Row>
    </Container>
    <DisclaimerCol>
      BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS. NO COMPARTA EL CONTENIDO CON MENORES.©2022 FREUNDE  VON MELO.
    </DisclaimerCol>
  </SectionContainer>
}