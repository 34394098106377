import React from "react"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { SectionHeader } from "./sectionHeader"
import styled from "styled-components"
import { Separator } from "./separators/separator"
import { useSectionData } from "../utilities/firebase"
import { SectionContainer } from "./sectionContainer"

const MainContainer = styled.div`
    padding-top: 16px;
    @media (min-width: 768px) {
        padding-top: 32px;
    }
`

const HistoryHeader = styled(SectionHeader)`
    text-align: center;
`

const TopImage = styled.img`
    margin: 0px 0px 16px 0px;
    width: 100%;
    height: 20vh;
    object-fit: cover;
    @media (min-width: 768px) {
        height: 20vh;
    }
`

const BottomImage = styled.img`
    margin: 16px 0px 0px 0px;
    width: 100%;
    height: 50vh;
    object-fit: cover;
    @media (min-width: 768px) {
        height: 50vh;
    }
`

type SectionData = {
    title: string
    description: string
    topImageURL: string
    bottomImageURL: string
}

export const BarServiceSection = () => {
    const sectionData = useSectionData<SectionData>("r5FDczYDjVm0gAaRvvj2");

    return <SectionContainer offset={-24} sectionId="atardeceres">
        {sectionData.topImageURL && <TopImage src={sectionData.topImageURL} />}
        <Separator variant="B" />
        <Container>
            <Row>
                <Col>
                    <HistoryHeader>{sectionData.title}</HistoryHeader>
                    <p style={{ maxWidth: 800, margin: "auto" }}>
                        {sectionData.description}
                    </p>
                </Col>
            </Row>
        </Container>
        <BottomImage src={sectionData.bottomImageURL} />
    </SectionContainer>
}