import * as React from "react"

export const Icon = () => {
     return <svg fill="inherit" preserveAspectRatio="none" viewBox="0 0 128 128">
          <g>
               <g>
                    <path d="M77.19,30.66H51.47c-11.4,0-20.65,9.24-20.65,20.65v25.72c0,11.4,9.24,20.64,20.65,20.64h25.72
       c11.4,0,20.65-9.24,20.65-20.64V51.31C97.84,39.9,88.59,30.66,77.19,30.66z M91.4,74.55c0,9.22-7.47,16.68-16.68,16.68H53.94
       c-9.21,0-16.68-7.47-16.68-16.68V53.77c0-9.21,7.47-16.68,16.68-16.68h20.78c9.21,0,16.68,7.47,16.68,16.68V74.55z"/>
                    <path d="M65.23,46.63c-9.75,0-17.65,7.9-17.65,17.65c0,9.74,7.9,17.65,17.65,17.65c9.75,0,17.65-7.91,17.65-17.65
       C82.88,54.53,74.98,46.63,65.23,46.63z M65.23,74.9c-5.87,0-10.62-4.76-10.62-10.62c0-5.87,4.75-10.62,10.62-10.62
       c5.86,0,10.62,4.75,10.62,10.62C75.85,70.14,71.09,74.9,65.23,74.9z"/>
                    <path d="M81.66,41.86c-2.48,0-4.49,2.01-4.49,4.49c0,2.48,2.01,4.49,4.49,4.49c2.48,0,4.49-2.01,4.49-4.49
       C86.15,43.88,84.13,41.86,81.66,41.86z"/>
               </g>
               <g>
                    <path d="M107.55,103.5c-1.74,0.52-2.71,2.46-3.53,3.96c-0.75,1.37-2.19,2.96-2.19,4.55c0,0.52,0.52,0.92,1.03,0.78
       c1.54-0.44,2.7-2.23,3.81-3.31c1.23-1.19,2.87-2.66,2.9-4.45C109.58,104.02,108.53,103.21,107.55,103.5z"/>
                    <path d="M126.17,68.61c7.54-30.46-23.93-61.26-52.56-66.68c-15.73-2.98-32.11-0.14-45.53,8.6C16.54,18.03,4.75,31.38,3.3,45.48
       c-0.03,0.31,0.06,0.56,0.2,0.78c-9.08,23.3,0.81,52.6,20.73,67.38c19.75,14.66,52.21,19.8,73.21,4.49
       c1.77-1.29,0.29-3.87-1.73-2.93c-0.76,0.35-1.52,0.7-2.28,1.05c-0.38-0.66-1.17-1.09-2.17-0.62c-13.98,6.63-33.39,3.28-47.21-1.65
       c-1.56-0.56-2.63,1.93-1.13,2.66c7.36,3.57,15.36,5.66,23.56,6.1c3.43,0.18,6.86,0.06,10.27-0.3c-5.21,1.3-10.6,1.92-16.39,1.39
       c-11.77-1.07-22.17-5.45-31.9-11.95c-11.4-7.62-19.19-16.91-22.88-30.19C2.36,70.18,2.2,58.06,5.73,46.67
       c0.15-0.11,0.29-0.25,0.39-0.43c0.62-1.16,1.22-2.31,1.82-3.44C4.44,51.8,3.41,61.8,4.98,71.34
       c2.97,18.08,13.16,30.03,28.17,39.84c1.8,1.18,3.6-1.85,1.69-2.86c-13.32-7.05-23.88-23.12-26.12-37.79
       c-2.47-16.21,3.43-31.42,13.07-44.32c0.82-1.1-0.92-2.3-1.86-1.42c-1.58,1.49-3.05,3.07-4.39,4.75c2.21-3.32,4.68-6.45,7.65-9.35
       c-0.68,1.14,0.41,2.87,1.78,1.99c3.5-2.25,6.89-4.64,10.52-6.68c2.59-1.46,5.35-2.63,8.01-3.96c0.43,0.14,0.93,0.16,1.5,0.02
       c19.29-4.9,38.5-2.79,53.54,11.31c0.58,0.55,1.3,0.3,1.66-0.19c-0.14,0.7,0.24,1.52,1.18,1.52c1.63,0,1.63-2.51,0-2.51
       c-0.46,0-0.77,0.21-0.97,0.49c0.04-0.28-0.04-0.58-0.3-0.86c-5.02-5.29-11.34-9.71-18.29-12.81
       c20.33,7.45,37.23,25.64,39.67,46.65c-1.52-6.71-4.31-13.2-8.42-19.06c-1.04-1.48-3.36-0.19-2.47,1.43
       c5.46,9.99,8.04,20.92,8.41,32.25c0.38,11.83-4.22,21.91-10.51,31.67c-0.64,0.99,0.84,2.21,1.66,1.39
       c0.38,0.19,0.85,0.2,1.25-0.17c9.08-8.46,15.72-21.16,14.83-33.86C126.21,68.73,126.18,68.68,126.17,68.61z"/>
                    <path d="M37.93,112.69c-0.4,0.93,0.13,1.86,1.06,2.18c0.68,0.23,1.3,0.47,2.02,0.56c1.23,0.15,1.69-1.52,0.95-2.28
       c-0.48-0.5-1.13-0.85-1.71-1.24C39.43,111.38,38.29,111.86,37.93,112.69z"/>
               </g>
          </g>
     </svg>
}