import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { debounce } from "../utilities/helper"
import { WhatsAppIcon } from "./icons"

const Icon = styled.a`
    position: fixed;
    z-index: 100;
    bottom: 32px;
    right: 32px;
    filter: drop-shadow(0px 4px 3px rgba(0,0,0,.5));
    transition: transform 0.5s;

    svg, img {
        transition: background-color 0.5s, fill 0.5s;

        border: 4px solid white;
        border-radius: 100%;

        width: 64px;
        height: 64px;
        
        padding: 4px;
        background-color: #8d1b1b;
        fill: white;

        :hover {
        background-color: white;
        fill: #8d1b1b;
        }
    }
`

export const FloatingActionButton = () => {
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [onBottom, setOnBottom] = useState(false);

    const handleScroll = debounce(() => {
        const currentScrollPos = window.pageYOffset;

        const reachedBottom = (window.innerHeight + window.scrollY) >= document.body.offsetHeight;

        setOnBottom(reachedBottom);

        setPrevScrollPos(currentScrollPos);
    }, 100, true);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);

    }, [prevScrollPos, onBottom, handleScroll]);

    return <Icon style={{ transform: onBottom ? "scale(0)" : "scale(1)" }} target="_blank" href={'https://wa.me/5493385596314'}>
        <WhatsAppIcon />
    </Icon>
}