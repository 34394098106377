// Your web app's Firebase configuration

import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query, where, doc, getDoc } from "firebase/firestore"
import { propTypes } from "gatsby-plugin-image/dist/src/components/gatsby-image.server";
import { useEffect, useState } from "react";
import { BeerType } from "../model";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCMlYMHBmiGDlY66zce2eDOjzLab2Ywz9k",
    authDomain: "freunde-von-melo-4b6e4.firebaseapp.com",
    projectId: "freunde-von-melo-4b6e4",
    storageBucket: "freunde-von-melo-4b6e4.appspot.com",
    messagingSenderId: "37821502031",
    appId: "1:37821502031:web:0f455aa387603268d3903a",
    measurementId: "G-WKKCCMB9XQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore();

export const useSectionData = <T>(id: string) => {
    const [ data, setData ] = useState({} as T);

    useEffect(() => {
        async function fetch() {
            const ref = doc(collection(db, "static-data"), id)
            const snapshot = await getDoc(ref)
            setData(
                snapshot.data() as T
            );
            return;
        }

        fetch()
    }, [])

    return data;
}

export const useBeerTypesList = () => {
    const [lists, setLists] = useState([] as BeerType[])

    useEffect(() => {
        async function fetch() {
            const q = query(collection(db, "beer-types"), where("enabled", "==", true))
            const types = await getDocs(q)
            setLists(
                types.docs
                .map(x => x.data() as BeerType)
                .sort((a, b) => a.order - b.order)
            );
            return;
        }

        fetch()
    }, [])
    return lists
}