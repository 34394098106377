import React from "react";
import styled from "styled-components";

const MainContainer = styled.div`
    padding-top: 24px;
    position: relative;
    @media (min-width: 768px) {
        padding-top: 32px;
    }
`

type Props = {
    sectionId: string
    offset?: number
    style?: React.CSSProperties
}

export const SectionContainer = (props: React.PropsWithChildren<Props>) => {
    return <MainContainer style={props.style}>
        <div id={props.sectionId} style={{ position: "absolute", top: props.offset ?? -56 }} />
        {props.children}
    </MainContainer>
}