import * as React from "react"

export const Icon = () => {
    return <svg fill="inherit" preserveAspectRatio="none" viewBox="0 0 512 512">
        <g>
            <g>
                <g>
                    <path d="M432.1,413.7c-7,2.1-10.9,9.9-14.2,15.9c-3,5.5-8.8,11.9-8.8,18.3c0,2.1,2.1,3.7,4.2,3.1
				c6.2-1.8,10.9-9,15.3-13.4c5-4.8,11.6-10.7,11.7-17.9C440.3,415.8,436.1,412.5,432.1,413.7z"/>
                    <path d="M507.1,273.2C537.5,150.5,410.7,26.4,295.4,4.6C232-7.4,166.1,4,112,39.2c-46.4,30.2-93.9,84-99.8,140.8
				c-0.1,1.2,0.2,2.3,0.8,3.1C-23.5,277,16.3,395,96.5,454.5c79.5,59,210.3,79.7,294.9,18.1c7.1-5.2,1.2-15.6-7-11.8
				c-3.1,1.4-6.1,2.8-9.2,4.2c-1.6-2.7-4.7-4.4-8.7-2.5c-56.3,26.7-134.5,13.2-190.2-6.6c-6.3-2.2-10.6,7.8-4.6,10.7
				c29.6,14.4,61.9,22.8,94.9,24.6c13.8,0.7,27.6,0.2,41.4-1.2c-21,5.3-42.7,7.7-66,5.6c-47.4-4.3-89.3-21.9-128.5-48.1
				c-45.9-30.7-77.3-68.1-92.2-121.6C8.5,279.5,7.8,230.7,22,184.8c0.6-0.4,1.2-1,1.6-1.7c2.5-4.7,4.9-9.3,7.3-13.9
				c-14.1,36.3-18.2,76.6-11.9,115c12,72.8,53,121,113.4,160.5c7.2,4.7,14.5-7.4,6.8-11.5C85.6,404.7,43.1,340,34.1,280.9
				c-9.9-65.3,13.8-126.5,52.6-178.5c3.3-4.4-3.7-9.3-7.5-5.7c-6.4,6-12.3,12.4-17.7,19.1c8.9-13.4,18.9-26,30.8-37.6
				c-2.7,4.6,1.6,11.5,7.2,8c14.1-9.1,27.7-18.7,42.4-26.9c10.4-5.9,21.6-10.6,32.3-16c1.7,0.6,3.7,0.7,6.1,0.1
				c77.7-19.7,155.1-11.2,215.6,45.6c2.4,2.2,5.2,1.2,6.7-0.8c-0.6,2.8,1,6.1,4.8,6.1c6.6,0,6.6-10.1,0-10.1c-1.8,0-3.1,0.8-3.9,2
				c0.1-1.1-0.2-2.4-1.2-3.4c-20.2-21.3-45.7-39.1-73.7-51.6c81.9,30,150,103.3,159.8,187.9c-6.1-27-17.4-53.2-33.9-76.8
				c-4.2-6-13.5-0.8-10,5.8c22,40.3,32.4,84.3,33.9,129.9c1.5,47.6-17,88.3-42.3,127.6c-2.6,4,3.4,8.9,6.7,5.6
				c1.5,0.8,3.4,0.8,5-0.7c36.6-34.1,63.3-85.2,59.7-136.4C507.3,273.7,507.1,273.4,507.1,273.2z"/>
                    <path d="M151.7,450.7c-1.6,3.7,0.5,7.5,4.3,8.8c2.7,0.9,5.2,1.9,8.1,2.2c4.9,0.6,6.8-6.1,3.8-9.2
				c-1.9-2-4.6-3.4-6.9-5C157.8,445.4,153.2,447.4,151.7,450.7z"/>
                </g>
            </g>
            <path d="M358.8,232c-2,0.2-4,0.3-5.9,0.3c-21.7,0-42-11-53.9-29.1v99.2c0,40.5-32.8,73.3-73.3,73.3
		c-40.5,0-73.3-32.8-73.3-73.3c0-40.5,32.8-73.3,73.3-73.3l0,0c1.5,0,3,0.1,4.5,0.2v36.1c-1.5-0.2-3-0.5-4.5-0.5
		c-20.7,0-37.4,16.8-37.4,37.4c0,20.7,16.8,37.4,37.4,37.4c20.7,0,38.9-16.3,38.9-37l0.4-168.5h34.6c3.3,31,28.3,55.2,59.3,57.5
		L358.8,232"/>
        </g>
    </svg>
}