import 'bootstrap/dist/css/bootstrap.min.css';

import * as React from "react"
import backgroundURI from "../images/background-pattern.png"

import styled from "styled-components"
import { Helmet } from "react-helmet"
import { VideoSection } from '../components/videoSection';
import { Navigation } from '../components/navigation';
import { HistorySection } from '../components/historySection';
import { VarietiesSection } from '../components/varietiesSection';
import { BarServiceSection } from '../components/barServiceSection';
import { GiftsSection } from '../components/giftsSection';
import { FooterSection } from '../components/footerSection';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { FloatingActionButton } from '../components/floatingActionButton';

const FixedBackground = styled.div`
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: black;
  z-index: -10;
`

const Main = styled.main`
  color: #232129;
  font-family: -apple-system, Roboto, sans-serif, serif;
  background-image: url("${backgroundURI}");
  background-color: rgba(250,200,110, .5);
  background-blend-mode: multiply;
  background-size: auto;

  /* ::before {
    content: "";
    display: block;
    background: radial-gradient(ellipse at center, rgba(255,255,255,0) 0%,rgba(230,150,30,.10) 85%,rgba(230,150,30,.20) 100%);
    position: fixed;
    top: 0; left: 0;right: 0; bottom: 0;
  } */
`

// markup
const IndexPage = () => {
  return (
    <Main>
      <FixedBackground />
      <FloatingActionButton />
      <Helmet title="Freunde Von Melo" defer={false}>
        <meta charSet="utf-8" />
        <title>Freunde Von Melo</title>
        <link rel="canonical" href="http://freundevonmelo.com.ar" />
      </Helmet>
      <Navigation />
      <VideoSection />
      <HistorySection />
      <VarietiesSection />
      <BarServiceSection />
      <GiftsSection />
      <FooterSection />
    </Main>
  )
}

export default IndexPage
