import React from "react"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { SectionHeader } from "./sectionHeader"
import styled from "styled-components"
import { Separator } from "./separators/separator"
import Stack from "react-bootstrap/esm/Stack"
import Ratio from "react-bootstrap/esm/Ratio"
import { useBeerTypesList, useSectionData } from "../utilities/firebase"
import { BeerType } from "../model"
import { SectionContainer } from "./sectionContainer"

const HistoryHeader = styled(SectionHeader)`
    text-align: center;
`

const Image = styled.img`
    width: calc(100% + 24px);
    margin-left: -12px;
    height: 50vh;
    object-fit: cover;
    @media (min-width: 768px) {
        width: 100%;
        margin-left: 0;
        height: 50vh;
    }
`

const StyledTypeLabel = styled.div`
    position: absolute;
    z-index: 1;
    top: 32px;
    padding: 0px 48px 0px 16px;
    height: 56px;
    color: white;
    display: flex;
    align-items: center;
    border-radius: 0px 32px 32px 0px;
    background: #8D1618;
    img {
        width: 88px;
        margin-right: 8px;
        background: #E9E9E9;
        border: 6px solid #8D1618;
        border-radius: 100%;
    }
    h4 {
        margin: 0;
    }
` 

const VarietyContainer = ({ data }: { data: BeerType  }) => {    
    return <Col md={{ span: 6 }} lg={{ span: 4 }}>
        <Stack gap={1}>
            <Ratio aspectRatio="4x3">
                <div>
                    <StyledTypeLabel>
                        <img src={data.iconURL} />
                        <h4>{data.name}</h4>
                    </StyledTypeLabel>
                    <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={data.imageURL} />
                </div>
            </Ratio>
            <p>{data.description}</p>
        </Stack>
    </Col>;
}


type SectionData = {
    title: string
    description: string
    imageURL: string
}

export const VarietiesSection = () => {
    const beerTypes = useBeerTypesList();
    const sectionData = useSectionData<SectionData>("OtQS5sLpsf2Ir4oNrlKj");
    return <SectionContainer sectionId="variedades">
        <Separator variant="B" />
        <Container>
            <Row>
                <Col>
                    <HistoryHeader>{sectionData.title}</HistoryHeader>
                    <p style={{ maxWidth: 800, margin: "auto" }}>
                        {sectionData.description}
                    </p>
                </Col>
            </Row>
            <Row style={{marginTop: 32}}>
                {
                    beerTypes.map((x, i) => {
                        return <VarietyContainer key={`variety-${i}`} data={x} />
                    })
                }
            </Row>
        </Container>
    </SectionContainer>
}