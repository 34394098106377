import * as React from "react"

export const Icon = () => <svg fill="inherit" preserveAspectRatio="none" viewBox="0 0 128 128">
     <g>
          <path d="M78.72,70.74c-1.03-0.58-1.95-1.12-3.13-1.69c-1.02-0.49-2.19-1.22-3.36-0.88c-0.85,0.25-1.46,1.03-2.03,1.66
		c-0.75,0.81-1.52,1.61-2.27,2.41c0,0-9.9-3.53-12.59-10.87c0.38-0.44,0.76-0.89,1.14-1.33c0.64-0.75,1.6-1.48,1.64-2.56
		c0.04-1.03-0.34-2.06-0.71-2.99c-0.24-0.6-0.49-1.2-0.73-1.81c-0.43-1.1-0.98-2.63-1.6-3.27c-0.83-0.86-2.24-1.04-3.35-0.49
		c0,0-6.46,3.18-4.4,9.41c0,0,4.04,17.57,23.56,21c0,0,7,0.93,8.83-3.33c0.4-0.92,0.66-2.21,0.58-3.21
		C80.21,71.83,79.52,71.19,78.72,70.74z M63.42,30.83C45.14,31,30.47,45.96,30.64,64.23c0.06,6.17,1.81,11.93,4.8,16.85l-4.01,16.86
		l16.54-4.93c4.78,2.59,10.26,4.05,16.08,4C82.33,96.84,97,81.88,96.82,63.6C96.65,45.32,81.69,30.65,63.42,30.83z M64.37,91.51
		c-5.78,0.06-11.16-1.69-15.61-4.69l-9.42,2.81l2.32-9.75c-3.12-4.41-4.97-9.77-5.03-15.58c-0.14-15.17,12.04-27.59,27.21-27.74
		c15.17-0.14,27.59,12.04,27.74,27.21C91.73,78.95,79.54,91.37,64.37,91.51z"/>
          <g>
               <path d="M107.3,103.58c-1.74,0.52-2.71,2.46-3.53,3.95c-0.75,1.36-2.19,2.96-2.19,4.55c0,0.52,0.52,0.92,1.03,0.78
			c1.54-0.44,2.69-2.23,3.8-3.31c1.23-1.19,2.87-2.65,2.9-4.45C109.33,104.1,108.29,103.29,107.3,103.58z"/>
               <path d="M125.9,68.74c7.53-30.42-23.9-61.18-52.5-66.6C57.69-0.83,41.33,2,27.93,10.73C16.41,18.22,4.64,31.55,3.19,45.64
			c-0.03,0.31,0.06,0.56,0.2,0.78c-9.07,23.27,0.81,52.53,20.7,67.3c19.73,14.64,52.14,19.78,73.12,4.48
			c1.77-1.29,0.29-3.87-1.73-2.93c-0.76,0.35-1.52,0.7-2.28,1.05c-0.38-0.66-1.17-1.09-2.16-0.62c-13.96,6.62-33.35,3.28-47.15-1.65
			c-1.56-0.55-2.63,1.93-1.13,2.66c7.35,3.56,15.34,5.66,23.53,6.09c3.42,0.18,6.85,0.06,10.26-0.3c-5.2,1.3-10.59,1.91-16.37,1.39
			c-11.75-1.07-22.15-5.44-31.86-11.93C16.91,104.34,9.13,95.06,5.45,81.8c-3.2-11.5-3.37-23.6,0.17-34.98
			c0.15-0.11,0.29-0.25,0.39-0.43c0.62-1.16,1.22-2.3,1.82-3.44c-3.49,9-4.52,18.99-2.96,28.52C7.83,89.53,18.01,101.46,33,111.26
			c1.8,1.18,3.6-1.85,1.69-2.86c-13.3-7.04-23.85-23.09-26.08-37.74c-2.47-16.19,3.42-31.38,13.05-44.27
			c0.82-1.1-0.92-2.3-1.86-1.42c-1.58,1.48-3.04,3.07-4.39,4.74c2.21-3.31,4.68-6.44,7.64-9.34c-0.68,1.14,0.41,2.86,1.78,1.98
			c3.5-2.25,6.88-4.63,10.51-6.68c2.59-1.46,5.35-2.62,8-3.96c0.43,0.14,0.93,0.16,1.5,0.02c19.27-4.9,38.46-2.78,53.47,11.3
			c0.58,0.55,1.3,0.3,1.65-0.19c-0.14,0.7,0.24,1.52,1.18,1.52c1.63,0,1.63-2.5,0-2.5c-0.46,0-0.77,0.21-0.97,0.49
			c0.03-0.28-0.04-0.58-0.3-0.85c-5.01-5.28-11.33-9.7-18.27-12.79c20.31,7.44,37.19,25.61,39.62,46.6
			c-1.52-6.71-4.31-13.18-8.41-19.04c-1.04-1.48-3.35-0.19-2.47,1.43c5.46,9.98,8.03,20.9,8.4,32.21
			c0.38,11.81-4.21,21.89-10.49,31.63c-0.64,0.99,0.84,2.2,1.65,1.38c0.38,0.19,0.85,0.2,1.25-0.17
			c9.07-8.45,15.7-21.14,14.81-33.82C125.94,68.86,125.91,68.81,125.9,68.74z"/>
               <path d="M37.78,112.77c-0.4,0.93,0.13,1.86,1.06,2.18c0.68,0.23,1.3,0.47,2.01,0.56c1.23,0.15,1.68-1.52,0.95-2.27
			c-0.48-0.5-1.13-0.85-1.71-1.23C39.27,111.45,38.13,111.93,37.78,112.77z"/>
          </g>
     </g>
</svg>