import React from "react";
import styled from "styled-components";
import logoURI from "../images/logo-negative.svg"

const StyledContainer = styled.div`
    width: 100%;
    height: 80vh;
    position: relative;
    video {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        object-fit: cover;
    }
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0px 4px 4px rgba(0,0,0,.75));
    color: white;

    img {
        max-width: 300px;
        max-height: 300px;
        width: 100%;
        @media (min-width: 768px) {
            max-width: 350px;
            max-height: 350px;
        }
    }

    h1 {
    }
`

export const VideoSection = () => {
    return <StyledContainer id="home">
        <video autoPlay loop muted>
            <source
                type="video/mp4"
                src="https://firebasestorage.googleapis.com/v0/b/freunde-von-melo-4b6e4.appspot.com/o/public%2Fvideo-freunde.mp4?alt=media"
            />
        </video>
        <ContentContainer>
            <img src={logoURI} />
            <h1 style={{ marginTop: 8, marginBottom: 8 }}>Cervecería artesanal</h1>
        </ContentContainer>
    </StyledContainer>
}